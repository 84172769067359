import { RouteNames } from '@vectroid/shared/const';
import { ApiDocsButton } from 'components/api-docs-button';
import { DropdownButton } from "components/catalyst/dropdown";
import { Heading } from "components/catalyst/heading";
import { CommonDropdown } from "components/common";
import { DashboardSkeleton } from 'components/skeletons';
import { ChevronDown, PlusIcon } from "lucide-react";
import { useEffect, useRef } from "react";
import { DashboadRecentlyViewed } from "../components/dashboard-recently-viewed";
import { useDashboard } from "../store";

export function DashboardPage() {
  const { datasets, indexes, loading, fetchResources } = useDashboard();
  const docsBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    fetchResources()
  }, []);

  function renderDashboard() {
    if (loading) {
      return <DashboardSkeleton />;
    }

    return <DashboadRecentlyViewed datasets={datasets} indexes={indexes} />;
  }

  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        pageHeading
        actions={
          <>
            <ApiDocsButton path="/quickstart/#2-create-a-dataset" ref={docsBtnRef} />
            <CommonDropdown
              className="!p-0"
              anchor="bottom start"
              menuItems={[
                {
                  href: RouteNames.CreateDataset,
                  key: "dataset",
                  label: "Dataset",
                },
                { href: RouteNames.CreateIndex, key: "index", label: "Index" },
              ]}
            >
              <DropdownButton color="amber">
                <PlusIcon className="size-4" strokeWidth={3} /> Create New{" "}
                <ChevronDown className="size-6" />
              </DropdownButton>
            </CommonDropdown>
          </>
        }
      >
        Dashboard
      </Heading>

      {renderDashboard()}
    </>
  );
}
